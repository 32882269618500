import { useEffect, useState } from "react";
import Footer from "../../components/layouts/footer";
import { API_URL, URLS } from "../../_config";
import { useToken } from "../../_common/hooks/authHook";
import { proposalStatus, proposalStatusButton, replaceUrlParams, showDate } from "../../_common/functions";
import toast from "react-hot-toast";
import Nav from "../../components/layouts/nav";
import { Link, useNavigate } from "react-router-dom";
import Icon from "../../components/icon";
import Swal from 'sweetalert2';

const MyBusinessPlan = () => {
    const [myBusinessPlan, setMyBusinessPlan] = useState<any>(null);
    const navigate: any = useNavigate();
    const tokenData: any = useToken();

    const getMyBusinessPlan = async () => {
        try {
            const response = await fetch(API_URL.ACCOUNT.MY_BUSINESS_PLAN, {
                headers: { Authorization: `Bearer ${tokenData}` }
            });
            const resp = await response.json();
            setMyBusinessPlan(resp.data)
            if (resp.message == 'Unautheticated.') {
                navigate(URLS.LOGOUT);
            }
        } catch (error) {
            console.log('Error occurred while making the API request.', error);
            // Handle error
            setMyBusinessPlan([])
        }
    }

    const pdfDownload = (plan: any, type: string) => {
        return new Promise((resolve, reject) => {
            const APIURL = type === 'plan' ? API_URL.ACCOUNT.MY_BUSINESS_PLAN_DOWNLOAD : API_URL.ACCOUNT.MY_PROPOSAL_DOWNLOAD;
            fetch(APIURL + "/" + plan.id, {
                headers: { Authorization: `Bearer ${tokenData}` }
            }).then(response => response.json())
                .then(resp => {
                    if (resp?.status == 200) {
                        const pdfFile = type === 'plan' ? resp.data.plan_pdf : resp.data.proposal_pdf;
                        window.open(pdfFile, '_blank'); // Open URL in a new tab
                        resolve(resp.message);
                    } else {
                        reject(resp.message);
                    }
                }).catch(error => {
                    // Handle error
                    console.error('Error:', error);
                    reject(error.message);
                });
        })
    }
    const pdfFileDownloadHandler = async (plan: any, type: string) => { // type: "plan" or "proposal" | "invoice"
        toast.promise(
            pdfDownload(plan, type), {
            loading: 'Checking...',
            success: (msg: any) => {
                return <b>{msg}</b>
            },
            error: (msg: any) => { return <b>{msg}</b> },
        });
    }

    useEffect(() => {
        getMyBusinessPlan();
    }, [])



    const proposalApproval = (params: any) => {
        return new Promise((resolve, reject) => {
            fetch(API_URL.ACCOUNT.MY_BUSINESS_APPROVAL, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${tokenData}` },
                body: JSON.stringify(params),
            }).then(response => response.json()).then(resp => {
                if (resp?.status == 200) { // Success 
                    resolve(resp.message);
                    getMyBusinessPlan();
                } else {
                    reject(resp.message);
                }
            }).catch(error => {
                reject(error.message);
            });
        })
    }

    const proposalApprovalHandler = async (planData: any) => {
        Swal.fire({
            title: 'Approve Proposal',
            text: `Are you sure you want to approve this proposal?`,
            icon: 'success',
            showCancelButton: true,
            confirmButtonColor: '#c4d72b',
            cancelButtonColor: '#44546A',
            cancelButtonText: 'No',
            confirmButtonText: `Yes, Approve it!`,
        }).then((result: any) => {
            if (result.isConfirmed) {
                toast.promise(
                    proposalApproval({ proposal_id: planData.id }), {
                    loading: 'Checking...',
                    success: (msg: any) => {
                        return <b>{msg}</b>
                    },
                    error: (msg: any) => { return <b>{msg}</b> },
                });
            }
        });

    }

    return (
        <>
            <Nav />

            <div className="body-part gray-bg mt-105">
                <div className="container text-center">
                    <h2 className="page-title">My <span>Business Plan</span></h2>
                    <p className="tag-line">Manage your Business Plan</p>


                    <div className="profile-box">
                        <div className="profile-box-title">
                            <div className="profile-icon"><Icon name="business-card" /></div>
                            <div className="profile-text">My Business Plan
                                {
                                    myBusinessPlan && myBusinessPlan.length > 0 &&
                                    <Link to={URLS.SUBSCRIPTION} className="float-right"><button type="button" className="master-orng-btn">Create Proposal</button></Link>
                                }

                            </div>
                        </div>
                        <div className="profile-box-data">
                            <div className="table-responsive">
                                <table className="table table-striped plan-craft-table">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Submitted date</th>
                                            <th>Business Plan</th>
                                            <th>Amount</th>
                                            <th>Proposal Status</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {myBusinessPlan && myBusinessPlan.map((plan: any, index: number) => {
                                            const addonsIds = plan?.addons.map((addon: any) => addon.addon_id).join(',');
                                            return (
                                                <tr key={plan?.id}>
                                                    <td>{index + 1}</td>
                                                    <td>{showDate(plan.created_at)}</td>
                                                    <td>
                                                        {plan?.subscription_plan?.subscription_plan_type?.title}
                                                        - <strong>{plan.subscription_plan?.title}</strong>
                                                    </td>
                                                    <td>
                                                        {plan.amount ?? '--'} {plan?.currency && plan?.currency.toUpperCase()}
                                                    </td>
                                                    <td className={`status ${proposalStatusButton(plan.status)}`}><span>{proposalStatus(plan.status)}</span></td>
                                                    <td className="actions">
                                                        <span title="View Proposal"><Link to={replaceUrlParams(URLS.ACCOUNT.MY_BUSINESS_PLAN_DETAILS, { proposalId: plan.id })}><Icon name="eye" className="text-blue-500" /></Link></span>
                                                        {
                                                            plan.status >= 2 &&
                                                            <span title="Download Proposal">
                                                                <Icon name="download-chat" onClick={() => pdfFileDownloadHandler(plan, 'proposal')} />
                                                            </span>
                                                        }
                                                        {
                                                            (plan.status == 0) &&
                                                            <span title="Edit Proposal">
                                                                <Link to={replaceUrlParams(URLS.BUSINESS_PLAN, { subscriptionId: plan.subscription_plan_id }) + "?addons=" + addonsIds + "&pId=" + plan.id}>
                                                                    <Icon name="edit" className="text-yellow-500 cursor-pointer" />
                                                                </Link>
                                                            </span>
                                                        }
                                                        {
                                                            (plan.status == 1) &&
                                                            <span title="Waiting for admin approval">
                                                                <Icon name="info" className="text-yellow-500" />
                                                            </span>
                                                        }
                                                        {
                                                            plan.status == 3 && <span title="Amount not set yet for payment.">
                                                                <Icon name="money-off" className="text-red-500" />
                                                            </span>
                                                        }
                                                        {
                                                            (plan.status == 2) &&
                                                            <>
                                                                <span title="Waiting for your approval">
                                                                    <Icon name="approval" onClick={() => proposalApprovalHandler(plan)} />
                                                                </span>
                                                            </>
                                                        }
                                                        {
                                                            plan.status == 4 && plan?.payment_status != 1 && <>
                                                                {
                                                                    plan.amount > 0 ?
                                                                        <span title="Please complete your payment.">
                                                                            <Link to={replaceUrlParams(URLS.ACCOUNT.PAYMENT.PAY, { proposalId: plan.id })}>
                                                                                <Icon name="payment" className="text-green-500 cursor-pointer" />
                                                                            </Link>
                                                                        </span>
                                                                        :
                                                                        <span title="Amount not set yet for payment."><Icon name="money-off" className="text-red-500" /></span>
                                                                }
                                                            </>
                                                        }
                                                        {
                                                            (plan.status == 5 && plan?.payment_status == 1) &&
                                                            <>
                                                                <span title={`Paid on ${showDate(plan?.last_payment_at)}`}><Icon name="paid" /></span>

                                                                {plan?.plan_pdf ? <span onClick={() => pdfFileDownloadHandler(plan, 'plan')} title={`${plan?.plan_pdf ? "Download Business Plan" : "Not ready to download plan"}`}>
                                                                    <Icon name="download-file-solid" className="text-green-500" />
                                                                </span> : <span title="Not ready to download plan."><Icon name="info" className="text-red-500" /></span>}

                                                                {plan?.invoice_pdf ? <Link to={plan?.invoice_pdf?.original} target="_blank" rel="noreferrer"
                                                                    // onClick={() => pdfFileDownloadHandler(plan, 'invoice')} 
                                                                    title={`${plan?.invoice_pdf ? "Download your invoice" : "Not ready to download invoice"}`}>
                                                                    <Icon name="invoice" className="text-teal-600 cursor-pointer" />
                                                                </Link> : <span title="Not ready to download invoice."><Icon name="info" className="text-red-500" /></span>}

                                                            </>
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        <tr>
                                            {
                                                myBusinessPlan && myBusinessPlan.length === 0 && <td colSpan={6} className="text-center">
                                                    <p className="mt-3 text-red-500">No data found!</p>
                                                    <div className="btn-wrap mt-3">
                                                        <Link to={URLS.SUBSCRIPTION} ><button type="button" className="master-orng-btn">Start Your Business Plan</button></Link>
                                                    </div>
                                                </td>
                                            }
                                            {
                                                !myBusinessPlan && <td colSpan={6} className="text-center"><p>Loading...</p></td>
                                            }
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>

    )
}

export default MyBusinessPlan; 