/* 
    this component use for breadcrumps and page title 
*/

import React, { HtmlHTMLAttributes } from 'react'
import { Link } from 'react-router-dom';
import { URLS } from '../../_config';

interface props extends HtmlHTMLAttributes<HTMLDivElement> {
    title: any;
    breadcrumbs?: any
}
const Breadcrumbs: React.FC<props> = ({
    children,
    title,
    breadcrumbs,
    ...args
}) => {

    return (
        <div className="breathcrumb" data-aos="fade-up">
            <ul>
                {
                    breadcrumbs && Object.keys(breadcrumbs).map((key: any, index: any) => {
                        return (
                            <>

                                <li key={`bed-${index}`} >
                                    {(breadcrumbs[key] == '#') ? key : <Link to={breadcrumbs[key]} className="de-select">{key}</Link>}
                                </li>
                                {
                                    (index < Object.keys(breadcrumbs).length - 1) && <li><i className='bx bx-chevron-right' ></i></li>
                                }
                            </>
                        )
                    }

                    )
                } 
            </ul>
        </div>






    )
}

export default Breadcrumbs;
