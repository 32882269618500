import React, { useEffect, useRef, useState } from 'react';
import Nav from '../../components/layouts/nav';
import Footer from '../../components/layouts/footer';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { API_URL, URLS } from '../../_config';
import { Controller, useForm } from 'react-hook-form';
import Error from '../../components/formFieldError';
import toast from 'react-hot-toast';
import { useToken, useUser } from '../../_common/hooks/authHook';
import { replaceUrlParams, showDate } from '../../_common/functions';
import './TicketReply.scss';
import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs';
import Icon from '../../components/icon';
import Swal from 'sweetalert2';

const TicketReply: React.FC = () => {
    const navigate: any = useNavigate();
    const tokenData: any = useToken();
    const userData: any = useUser();
    const [ticketDetails, setTicketDetails] = useState<any>(null);
    const urlParams: any = useParams();
    const ticketId = urlParams?.ticketId;
    const { control, formState: { errors }, handleSubmit, reset } = useForm();
    const [selectedFiles, setSelectedFiles] = useState<string | "">("");

    const eventPicRef: any = useRef();

    const getTicketDetails = async () => {
        try {
            const response = await fetch(replaceUrlParams(API_URL.ACCOUNT.TICKET.DETAILS, { ticketId: ticketId }), {
                headers: { Authorization: `Bearer ${tokenData}` }
            });
            const resp = await response.json();
            setTicketDetails(resp.data)
            if (resp.message == 'Unautheticated.') {
                navigate(URLS.LOGOUT);
            }
        } catch (error) {
            console.log('Error occurred while making the API request.', error);
            // Handle error
            setTicketDetails([])
        }
    }

    useEffect(() => {
        getTicketDetails();
    }, [])

    const handleFileChange = (event: any) => {
        const selectedFile = event.target.files[0];
        // Do something with the selected file, e.g., display its name
        if (selectedFile) {
            const reader = new FileReader();
            reader.onloadend = () => {
                // Base64 string representation of the selected file
                const base64String = reader.result as string | null;
                if (base64String !== null) {
                    setSelectedFiles(base64String);
                }
                // You can use the base64String as needed (e.g., set it in the state)
            };
            // Read the selected file as a Data URL (Base64)
            reader.readAsDataURL(selectedFile);
        }
        // setSelectedFiles(event.target.files);
    };

    const saveReply = (params: any) => {
        return new Promise((resolve, reject) => {
            fetch(API_URL.ACCOUNT.TICKET.REPLY, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${tokenData}` },
                body: JSON.stringify(params),
            })
                .then(response => response.json())
                .then(resp => {
                    if (resp?.status == 200) {
                        // Handle success  
                        resolve(resp.message);
                        reset({});
                        getTicketDetails();
                        eventPicRef.current.value = "";
                        setSelectedFiles("");
                    } else {
                        reject(resp.message);
                    }
                })
                .catch(error => {
                    // Handle error
                    console.error('Error:', error);
                    reject(error.message);
                });
        })
    }


    const saveFormHandler = async (data: any) => {
        data.support_ticket_id = ticketId;
        data.attachments = [selectedFiles];
        toast.promise(
            saveReply(data), {
            loading: 'Sending...',
            success: (msg: any) => {
                return <b>{msg}</b>
            },
            error: (msg: any) => { return <b>{msg}</b> },
        });
    }


    const closeTicketSubmit = (params: any) => {
        return new Promise((resolve, reject) => {
            fetch(API_URL.ACCOUNT.TICKET.CLOSE_TICKET, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${tokenData}` },
                body: JSON.stringify(params),
            }).then(response => response.json()).then(resp => {
                if (resp?.status == 200) {
                    resolve(resp.message);
                    getTicketDetails();
                } else {
                    reject(resp.message);
                }
            }).catch(error => {
                reject(error.message);
            });
        })
    }


    const closeTicketHandler = async () => {
        Swal.fire({
            title: 'Close Ticket',
            text: `Are you sure you want to close this ticket?`,
            icon: 'success',
            showCancelButton: true,
            confirmButtonColor: '#c4d72b',
            cancelButtonColor: '#44546A',
            cancelButtonText: 'No',
            confirmButtonText: `Yes, Close it!`,
        }).then((result: any) => {
            if (result.isConfirmed) {
                toast.promise(
                    closeTicketSubmit({ ticket_id: ticketId }), {
                    loading: 'Closing...',
                    success: (msg: any) => {
                        return <b>{msg}</b>
                    },
                    error: (msg: any) => { return <b>{msg}</b> },
                });
            }
        });

    }


    return (
        <div className='bg-white'>
            <Nav />

            <div className="chat_content">
                <div className="container pt-2">
                    <Breadcrumbs title={`Create Ticket`}
                        breadcrumbs={{
                            'Home': URLS.HOME,
                            'My Tickets': URLS.ACCOUNT.TICKET.LIST,
                            'Reply': '#',
                        }} />

                    <div className="chat_content_details mt-3">
                        <div className="chat_heading">
                            <div className="chat_headingdescription">
                                <h3>{ticketDetails?.ticket_no}: {ticketDetails == null ? 'Loading...' : ticketDetails?.subject}</h3>
                                <p>Status: {ticketDetails?.status_text} | Date created: <span>{showDate(ticketDetails?.created_at)}</span> | Last Updated:  <span>{showDate(ticketDetails?.updated_at)}</span>
                                </p>
                            </div>
                            <div className="close_case mr-3"><Link to={URLS.ACCOUNT.TICKET.LIST} className=''>Back to list</Link></div>

                            {
                                ticketDetails?.status_text == 'Closed' ?
                                    <div className="close_case"><Link to="#" className='closed_case'>Closed</Link></div>
                                    :
                                    <div className="close_case">
                                        <Link to="#" onClick={closeTicketHandler}>Close Ticket</Link>
                                    </div>
                            }

                        </div>
                        <div className="chat_description">
                            <div className="d-flex align-items-center justify-content-between">
                                <h3>Description</h3>
                                {
                                    ticketDetails?.attachments && ticketDetails?.attachments.map((attachment: any, index: number) => {
                                        return (
                                            <Link to={attachment.original} target='_blank' className="download_icon" key={index}>
                                                <i className="bx bx-download"></i>
                                            </Link>
                                        )
                                    })
                                }
                                {/* <a href="#" className="download_icon"> <i className="bx bx-download"></i> </a> */}
                            </div>

                            <p><span>{ticketDetails == null ? 'Loading...' : ticketDetails?.description}</span></p>
                        </div>
                        <div className="chat_comments">
                            {
                                ticketDetails && ticketDetails?.status_text != 'Closed' &&
                                <form onSubmit={handleSubmit(saveFormHandler)}>
                                    <div className="row">
                                        <div className="col-lg-8 col-md-8">
                                            <div className="mb-3">
                                                <label className="form-label">Comment</label>
                                                {/* <textarea name="" id="" className="form-control"></textarea> */}
                                                <Controller
                                                    defaultValue={""}
                                                    control={control}
                                                    name={`reply_text`}
                                                    rules={{ required: "The field is required." }}
                                                    render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                                        <input type="text" name={name} onChange={onChange} value={value} className="form-control my-0 py-1 red-border" autoComplete="off" placeholder="Write a message..." spellCheck="false" data-ms-editor="true" />
                                                    )}
                                                />
                                                <Error error={errors[`reply_text`]} customClass="text-xs" />

                                            </div>

                                        </div>
                                        <div className="col-lg-4 col-md-4">
                                            <div className="mb-3">
                                                <label className="form-label">Attachment</label> 
                                                <Controller
                                                    defaultValue={""}
                                                    control={control}
                                                    name="attachments"
                                                    render={({
                                                        field: { onChange, onBlur, value, name, ref },
                                                    }) => (
                                                        <input
                                                            type="file"
                                                            accept='image/*,.pdf'
                                                            // multiple
                                                            ref={eventPicRef}
                                                            onChange={(e) => {
                                                                handleFileChange(e);
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <button className="comment_btn mt-2" type='submit'>Add Comment</button>
                                </form>
                            }



                            <h3>Comments</h3>
                            {
                                ticketDetails && ticketDetails?.replies && ticketDetails?.replies.map((comment: any, index: number) => {
                                    return (
                                        <div className="comment_area" key={comment.id}>
                                            <div className="comment_arealeft">
                                                <div className={`comment_arealeftimg ${comment?.reply_by_title}`}>
                                                    {
                                                        comment?.reply_by_title == 'user' ?
                                                            // `${userData?.first_name.charAt(0)}${userData?.last_name.charAt(0)}`
                                                            <img src={userData?.avatar?.thumb} alt={`${userData?.first_name.charAt(0)}${userData?.last_name.charAt(0)}`} style={{ height: '100%' }} />
                                                            : <Icon name='support-agent' />
                                                    }
                                                </div>
                                            </div>
                                            <div className="comment_arearight">
                                                {/* <h4> { comment?.reply_by_title == 'user' ? `${userData?.first_name} ${userData?.last_name}` : 'Admin' } </h4> */}
                                                <div className={`comment_name ${comment?.reply_by_title}`}>
                                                    {comment?.reply_by_title == 'user' ? `${userData?.first_name} ${userData?.last_name}` : 'Support'}

                                                </div>
                                                <div className="comment_date flex">
                                                    {showDate(comment.created_at)}
                                                    <span className='ml-5'>

                                                        {
                                                            comment?.attachments && comment?.attachments.map((attachment: any, index: number) => {
                                                                return (
                                                                    <Link to={attachment.original} key={`replyFile_${comment.id + index}`} target='_blank'>
                                                                        <Icon name="download-file" />
                                                                    </Link>
                                                                )
                                                            })
                                                        }
                                                    </span>
                                                </div>

                                                <p>{comment?.reply_text}</p>

                                            </div>
                                        </div>
                                    )
                                })
                            }
                            {
                                ticketDetails && ticketDetails?.replies && ticketDetails?.replies.length === 0 &&
                                <div className="comment_area">
                                    <div className="comment_arearight">
                                        <p>No comments found!</p>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );

};

export default TicketReply;