import React, { useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import { URLS } from "../../_config"
import toast from "react-hot-toast";
import { useToken } from "../../_common/hooks/authHook";
import { replaceUrlParams } from "../../_common/functions";
import { useAuthApi } from "../../_common/hooks/api/authApiHook";
import { APP_NAME, LOGO_URL } from "../../_config/site_urls";

const EmailLogin = () => {
    const navigate: any = useNavigate();
    const authApi = useAuthApi();
    let tokenData: any = useToken('email-auto-login');

    const queryParams = new URLSearchParams(window.location.search);
    const _pid = queryParams.get("pid");
    const _token = queryParams.get("token");
    const _type = queryParams.get("type");

    const submitVerifyOtp = (params: any) => {
        return new Promise((resolve, reject) => {
            authApi.verifyOtpAndLogin(params, (message: any, resp: any) => {
                if (resp?.status == 200) {
                    resolve(resp.message);
                } else {
                    reject(resp.message);
                }
            }, (message: any, data: any) => {
                reject(message)
                console.log('error Response:==', data);
            })
        })
    }

    const saveFormHandler = async (data: any) => {
        toast.promise(
            submitVerifyOtp(data), {
            loading: 'Checking for auto login...',
            success: (msg: any) => {
                localStorage.setItem("loginEmail", '');
                navigate(URLS.ACCOUNT.MY_BUSINESS_PLAN);
                return <b>{msg}</b>
            },
            error: (msg: any) => {
                navigate(URLS.LOGIN);
                return <b>{msg}</b>
            },
        });
    }


    useEffect(() => {
        if (_pid && _token) {
            if (tokenData) {
                if (_type === 'payment') {
                    navigate(replaceUrlParams(URLS.ACCOUNT.PAYMENT.PAY, { proposalId: _pid }));
                } else {
                    navigate(replaceUrlParams(URLS.ACCOUNT.MY_BUSINESS_PLAN_DETAILS, { proposalId: _pid }));
                }
            } else {
                saveFormHandler({ pid: _pid, token: _token });
            }
        }
    }, [])

    return (
        <React.Fragment>
            <div className="login-bg"></div>
            <div className="login-box" style={{ width: '50%' }}>
                <div className="login-box-left" style={{ width: '100%' }}>
                    <Link to={URLS.HOME} className="logo-login"><img src={LOGO_URL} alt={APP_NAME} /></Link>
                    <div className="login-text" data-aos="fade-up">
                        <h2>Hey!</h2>
                        {/* <p>Welcome Back.</p>
                        <p>You are just one step away to your feed</p> */}
                        <h3 className="text-red-500">We are checking for email auto login...</h3>
                    </div>
                </div>
                {/* <div className="login-box-right">
                    <h3 className="text-red-500 text-center mt-10">Checking for auto login...</h3>

                </div> */}
            </div>

        </React.Fragment>
    )
}

export default EmailLogin