import React, { useRef, useState } from 'react';
import Nav from '../../components/layouts/nav';
import Footer from '../../components/layouts/footer';
import { API_URL, URLS } from '../../_config';
import { Controller, useForm } from 'react-hook-form';
import Error from '../../components/formFieldError';
import FormTexAreatInput from '../../_common/form-elements/textareaInput';
import toast from 'react-hot-toast';
import { useToken } from '../../_common/hooks/authHook';
import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs';
import { useNavigate } from 'react-router-dom';

const TicketCreate: React.FC = () => {
    const tokenData: any = useToken();
    const navigate: any = useNavigate();
    const { control, formState: { errors }, handleSubmit, register, reset, watch } = useForm();

    const [selectedFiles, setSelectedFiles] = useState<string | "">("");

    const eventPicRef: any = useRef();

    const handleFileChange = (event: any) => {
        const selectedFile = event.target.files[0];
        // Do something with the selected file, e.g., display its name
        if (selectedFile) {
            const reader = new FileReader();
            reader.onloadend = () => {
                // Base64 string representation of the selected file
                const base64String = reader.result as string | null;
                if (base64String !== null) {
                    setSelectedFiles(base64String);
                }
                // You can use the base64String as needed (e.g., set it in the state)
            };
            // Read the selected file as a Data URL (Base64)
            reader.readAsDataURL(selectedFile);
        }
        // setSelectedFiles(event.target.files);
    };

    const saveTicket = (params: any) => {
        return new Promise((resolve, reject) => {
            fetch(API_URL.ACCOUNT.TICKET.CREATE, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${tokenData}` },
                body: JSON.stringify(params),
            })
                .then(response => response.json())
                .then(resp => {
                    if (resp?.status == 200) {
                        // Handle success  
                        resolve(resp.message);
                        reset({});
                        navigate(URLS.ACCOUNT.TICKET.LIST);
                    } else {
                        reject(resp.message);
                    }
                })
                .catch(error => {
                    // Handle error
                    console.error('Error:', error);
                    reject(error.message);
                });
        })
    }

    const saveFormHandler = async (data: any) => {
        const params = {
            subject: data.subject,
            description: data.description,
            attachments: [selectedFiles]
        }
        toast.promise(
            saveTicket(params), {
            loading: 'Uploading...',
            success: (msg: any) => {
                return <b>{msg}</b>
            },
            error: (msg: any) => { return <b>{msg}</b> },
        });


    }



    return (
        <div className='bg-white'>
            <Nav />

            <div className="inner-page-banner-size contact-banner mt-105">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <Breadcrumbs title={`Create Ticket`}
                                breadcrumbs={{
                                    'Home': URLS.HOME,
                                    'My Tickets': URLS.ACCOUNT.TICKET.LIST,
                                    'Create Ticket': '#',
                                }} />

                            <div className="about-text mt-40">
                                <h2 data-aos="fade-up">Create your <span>Ticket</span></h2>
                                {/* <p data-aos="fade-up">Get in touch with the PlanCrafter team and we'll get back to you and<br />help as soon as we can!</p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="body-part warm-bg">
                <div className="container">
                    <div className="sub-heading text-center mb-70 ">
                        <h2 data-aos="fade-up" className="gray-col">Enter your ticket details</h2>
                        {/* <p data-aos="fade-up" className="gray-col">Enter your ticket details on here.</p> */}
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12">
                            <div className="contact-img" data-aos="fade-right"><img src="/images/img/contact.png" alt='' /></div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div className="contact-form">
                                <form onSubmit={handleSubmit(saveFormHandler)} encType="multipart/form-data">
                                    <div className="mb-4 float-input light-float" data-aos="fade-left" data-aos-duration="2000">
                                        <label className="form-label">Subject <sup className="text-danger">*</sup></label>
                                        <Controller
                                            defaultValue={""}
                                            control={control}
                                            name={`subject`}
                                            rules={{ required: "The field is required." }}
                                            render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                                <input type="text" name={name} onChange={onChange} value={value} className="form-control" autoComplete="off" placeholder="Enter your name" spellCheck="false" data-ms-editor="true" />
                                            )}
                                        />
                                        <Error error={errors[`subject`]} customClass="text-xs" />
                                    </div>
                                    <div className="mb-4 float-input light-float" data-aos="fade-left" data-aos-duration="2000">
                                        <label className="form-label">Your message <sup className="text-danger">*</sup></label>
                                        <Controller
                                            defaultValue={""}
                                            control={control}
                                            name={`description`}
                                            rules={{ required: "The field is required." }}
                                            render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                                <FormTexAreatInput
                                                    name={name}
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    value={value}
                                                    inputRef={ref}
                                                    type="text"
                                                    rows={2}
                                                />
                                            )}
                                        />
                                        <Error error={errors[`description`]} customClass="text-xs" />
                                    </div>
                                    <div className="mb-4 float-input light-float" data-aos="fade-left" data-aos-duration="2000">
                                        <div>
                                            <label htmlFor="files">Upload Files:</label>
                                            <Controller
                                                defaultValue={""}
                                                control={control}
                                                name="attachments[]"
                                                render={({
                                                    field: { onChange, onBlur, value, name, ref },
                                                }) => (
                                                    <input
                                                        accept='image/*,.pdf'
                                                        type="file"
                                                        // multiple
                                                        ref={eventPicRef}
                                                        onChange={(e) => {
                                                            handleFileChange(e);
                                                        }}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <button type="submit" className="master-orng-btn w-100" data-aos="fade-left" data-aos-duration="2000">Submit</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );

};

export default TicketCreate;